// @mui icons.
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddCardIcon from '@mui/icons-material/AddCard';
import CasinoIcon from '@mui/icons-material/Casino';
import ContactsIcon from '@mui/icons-material/Contacts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExtensionIcon from '@mui/icons-material/Extension';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PageviewIcon from '@mui/icons-material/Pageview';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleIcon from '@mui/icons-material/People';
import PolicyIcon from '@mui/icons-material/Policy';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import LocationIcon from '@mui/icons-material/LocationCity';

// components
// import SvgIconStyle from '../../../components/SvgIconStyle';
// type
import { NavSectionConfigProps } from '../../../components/nav-section/type';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

const ICONS = {
  auditors: <PolicyIcon />,
  banks: <AccountBalanceIcon />,
  bets: <PriceChangeIcon />,
  dashboard: <DashboardIcon />,
  deposit: <AddCardIcon />,
  gameProviders: <ExtensionIcon />,
  games: <CasinoIcon />,
  kyc: <PageviewIcon />,
  partners: <ContactsIcon />,
  reports: <FactCheckIcon />,
  streams: <SettingsInputAntennaIcon />,
  transactions: <ReceiptLongIcon />,
  users: <PeopleIcon />,
  withdraw: <PaymentsIcon />,
  sportevents: <SportsScoreIcon />,
  locations: <LocationIcon />,
};

export const getNavConfigForRoles = (
  roles: undefined | null | Array<string>,
): Array<NavSectionConfigProps> => {
  let navConfig: Array<NavSectionConfigProps> = [];
  if (roles) {
    // ADMIN.
    if (roles.includes('admin')) {
      navConfig = [
        {
          subheader: 'CUSTOMER',
          items: [
            { title: 'Dashboard', path: '/customer/dashboard', icon: ICONS.dashboard },
            { title: 'KYC Pending', path: '/customer/kyc', icon: ICONS.kyc },
            { title: 'Users', path: '/customer/users', icon: ICONS.users },
            { title: 'Transactions', path: '/customer/transactions', icon: ICONS.transactions },
            { title: 'Bets', path: '/customer/bets', icon: ICONS.bets },
            { title: 'Admin Bets', path: '/customer/adminbets', icon: ICONS.bets },
            { title: 'Reports', path: '/customer/reports', icon: ICONS.reports },
            { title: 'Streams', path: '/customer/streams', icon: ICONS.streams },
            { title: 'Locations', path: '/customer/locations', icon: ICONS.locations },
            { title: 'Daily Reports', path: '/customer/dailyreports', icon: ICONS.reports },
          ],
        },
        {
          subheader: 'BUSINESS',
          items: [
            { title: 'Sport Events', path: '/business/sportevents', icon: ICONS.sportevents },
            { title: 'Banks', path: '/business/banks', icon: ICONS.partners },
            { title: 'Auditors', path: '/business/auditors', icon: ICONS.auditors },
            { title: 'Game Providers', path: '/business/providers', icon: ICONS.gameProviders },
            { title: 'Games', path: '/business/games', icon: ICONS.games },
            { title: 'Partners', path: '/business/partners', icon: ICONS.partners },
            { title: 'Deposits', path: '/business/deposit', icon: ICONS.deposit },
            { title: 'Withdraw', path: '/business/withdraw', icon: ICONS.withdraw },
            { title: 'Cashier & Terminal', path: '/business/cashierterminal', icon: ICONS.deposit },
            { title: 'Odd Balancer', path: '/business/balancer', icon: ICONS.banks },
          ],
        },
      ];
    }
    // PAGCOR
    else if (roles.includes('pagcor')) {
      navConfig = [
        {
          subheader: 'CUSTOMER',
          items: [
            { title: 'Dashboard', path: '/customer/dashboard', icon: ICONS.dashboard },
            { title: 'Users', path: '/customer/users', icon: ICONS.users },
            { title: 'Deposits', path: '/customer/deposits', icon: ICONS.deposit },
            { title: 'Withdraws', path: '/customer/withdraws', icon: ICONS.withdraw },
            { title: 'Transactions', path: '/customer/transactions', icon: ICONS.transactions },
            { title: 'Bets', path: '/customer/bets', icon: ICONS.bets },
            { title: 'Admin Bets', path: '/customer/adminbets', icon: ICONS.bets },
            { title: 'Reports', path: '/customer/reports', icon: ICONS.reports },
            { title: 'Games', path: '/business/games', icon: ICONS.games },
            { title: 'Auditors', path: '/business/auditors', icon: ICONS.auditors },
          ],
        },
      ];
    } else if (roles.includes('finance')) {
      navConfig = [
        {
          subheader: 'CUSTOMER',
          items: [
            { title: 'Deposits', path: '/customer/deposits', icon: ICONS.deposit },
            { title: 'Users', path: '/customer/users', icon: ICONS.users },
            { title: 'Withdraws', path: '/customer/withdraws', icon: ICONS.withdraw },
            { title: 'Transactions', path: '/customer/transactions', icon: ICONS.transactions },
            { title: 'Bets', path: '/customer/bets', icon: ICONS.bets },
            { title: 'Daily Reports', path: '/customer/dailyreports', icon: ICONS.reports },
          ],
        },
      ];
    } else if (roles.includes('csr')) {
      navConfig = [
        {
          subheader: 'CUSTOMER',
          items: [
            { title: 'Users', path: '/customer/users', icon: ICONS.users },
            { title: 'KYC Pending', path: '/customer/kyc', icon: ICONS.kyc },
            { title: 'Deposits', path: '/customer/deposits', icon: ICONS.deposit },
            { title: 'Withdraws', path: '/customer/withdraws', icon: ICONS.withdraw },
            { title: 'Transactions', path: '/customer/transactions', icon: ICONS.transactions },
            { title: 'Bets', path: '/customer/bets', icon: ICONS.bets },
          ],
        },
      ];
    } else if (roles.includes('fairplaymx') || roles.includes('nemoaudit')) {
      navConfig = [
        {
          subheader: 'CUSTOMER',
          items: [{ title: 'Daily Reports', path: '/customer/dailyreports', icon: ICONS.reports }],
        },
      ];
    } else if (roles.includes('cashier')) {
      navConfig = [
        {
          subheader: 'BUSINESS',
          items: [
            { title: 'Cash In', path: '/business/cashin', icon: ICONS.deposit },
            { title: 'Cash Out', path: '/business/cashout', icon: ICONS.withdraw },
          ],
        },
      ];
    }
    // KYC
    else if (roles.includes('kyc')) {
      navConfig = [
        {
          subheader: 'CUSTOMER',
          items: [{ title: 'KYC Pending', path: '/customer/kyc', icon: ICONS.kyc }],
        },
      ];
    } else if (roles.includes('cor')) {
      navConfig = [
        {
          subheader: 'CUSTOMER',
          items: [{ title: 'Streams', path: '/customer/streams', icon: ICONS.streams }],
        },
      ];
    }

    //
  }

  return navConfig;
};
